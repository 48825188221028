<div class="footer">
  <div class="footerInner">
    <a href="#" target="_blank" rel="noopener noreferrer" class="logo">
      {{config.getConfig().title}}
    </a>
    <br />
    <p class="mb-0">
      Copyright © 2022 {{config.getConfig().title}} | v2.6.3.1
      <!-- |
      <a href="https://mt-legal.s3-us-west-2.amazonaws.com/ellis_island/ELLIS+ISLAND+CASINO+PRIVACY+POLICY.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a> -->
    </p>
  </div>
</div>